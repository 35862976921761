import React, { useState, useEffect, useReducer } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faBolt,
  faCube,
  faSyringe
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { SpecialitiesArray } from '../../modules/cs/employee/model/specialities-array';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Employee } from '../../modules/cs/common/components/employee';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { IEmployeeNode } from '../../modules/common/model/graphql-types';
import Select from 'react-select';

interface IEmployeeNodes {
  nodes: IEmployeeNode[];
}

interface IEmployeeEntry {
  allEmployees: IEmployeeNodes;
}

interface IProps {
  data: IEmployeeEntry;
}

import './characters.scss';

const CharactersPage: React.FC<IProps> = ({ data }) => {
  const REQUIRED_EMPLOYEES_PER_FACTION = 3;
  const [visibleEmployees, setVisibleEmployees] = useState(
    data.allEmployees.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [region, setRegion] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  let factions = [];

  const grps = lodash.groupBy(data.allEmployees.nodes, 'title');
  Object.keys(grps).forEach((key) => {
    if (grps[key].length >= REQUIRED_EMPLOYEES_PER_FACTION) {
      factions.push({
        label: key,
        value: key
      });
    }
  });

  factions = [{ label: 'Any Faction', value: 'all' }].concat(
    lodash.orderBy(factions, 'label')
  );

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'N', value: 'n' },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' },
          { label: 'A-SSR', value: 'a-ssr' }
        ]
      },
      {
        key: 'special',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'alterium',
            tooltip: 'Alternium Reactor',
            image: <FontAwesomeIcon icon={faCube} width="18" />
          },
          {
            value: 'rearm',
            tooltip: 'Rearm',
            image: <FontAwesomeIcon icon={faSyringe} width="18" />
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Counter',
            tooltip: 'Counter',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_counter.png"
                width={20}
                alt="Counter"
              />
            )
          },
          {
            value: 'Mech',
            tooltip: 'Mech',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_mech.png"
                width={20}
                alt="Mech"
              />
            )
          },
          {
            value: 'Soldier',
            tooltip: 'Soldier',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_soldier.png"
                width={20}
                alt="Soldier"
              />
            )
          },
          {
            value: 'Corrupted Object',
            tooltip: 'Corrupted Object',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_co.png"
                width={20}
                alt="Corrupted Object"
              />
            )
          }
        ]
      },
      {
        key: 'role',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            tooltip: 'Defender',
            value: 'defender',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            tooltip: 'Striker',
            value: 'striker',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_striker.png"
                width={20}
                alt="Striker"
              />
            )
          },
          {
            tooltip: 'Ranger',
            value: 'ranger',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_ranger.png"
                width={20}
                alt="Ranger"
              />
            )
          },
          {
            tooltip: 'Sniper',
            value: 'sniper',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_sniper.png"
                width={20}
                alt="Sniper"
              />
            )
          },
          {
            tooltip: 'Supporter',
            value: 'supporter',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_support.png"
                width={20}
                alt="Supporter"
              />
            )
          },
          {
            tooltip: 'Tower',
            value: 'tower',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_tower.png"
                width={20}
                alt="Tower"
              />
            )
          },
          {
            tooltip: 'Siege',
            value: 'siege',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_siege.png"
                width={20}
                alt="Siege"
              />
            )
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: SpecialitiesArray },
      { key: 'faction', type: 'dropdown', values: factions },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allEmployees.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.fullName
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        if (activeFilters.rarity.toLowerCase() === 'a-ssr') {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === 'ssr' && emp.isAwakened === true
          );
        } else {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase() &&
              emp.isAwakened === false
          );
        }
      }
      if (activeFilters.special && activeFilters.special !== 'all') {
        if (activeFilters.special.toLowerCase() === 'rearm') {
          filtered = filtered.filter((emp) => emp.isRearmed === true);
        } else if (activeFilters.special.toLowerCase() === 'alterium') {
          filtered = filtered.filter((emp) => emp.hasAlternium === true);
        } else {
          filtered = filtered.filter(
            (emp) => emp.hasAlternium === false && emp.isRearmed === false
          );
        }
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.indexOf(activeFilters.type) > -1
        );
      }
      if (activeFilters.role && activeFilters.role !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.role.toLowerCase() === activeFilters.role.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.title.toLowerCase() === activeFilters.faction.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      // filtered = filtered.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)
      filtered = filtered.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
      forceUpdate();
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.fullName < b.fullName ? 1 : -1));
      forceUpdate();
    } else if (sortOption === 'unitid') {
      filtered = filtered.sort((a, b) => (a.unitId > b.unitId ? 1 : -1));
      forceUpdate();
    } else if (sortOption === 'releaseDateKR') {
      filtered = filtered.sort((a, b) =>
        a.krReleaseDate < b.krReleaseDate ? 1 : -1
      );
      forceUpdate();
    }

    setVisibleEmployees(filtered);
  }, [activeFilters, data.allEmployees.nodes, sortOption, region]);

  const serverOptions = [{ value: 'KR', label: 'KR' }];

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' },
    { value: 'unitid', label: 'Sort by Unit ID' },
    { value: 'releaseDateKR', label: 'Sort by Release Date (KR)' }
  ];

  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  let defaultRegion = region
    ? serverOptions.find((opt) => opt.value === region)
    : null;
  if (defaultRegion === null) {
    defaultRegion = serverOptions[0];
    setRegion(defaultRegion.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-page '} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/counterside/categories/category_employees.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Counter Side Characters</h1>
          <h2>List of characters available in Counter Side.</h2>
          <p>
            Last updated: <strong>24/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleEmployees.length} character
            {visibleEmployees.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar counter-side">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Configure</Accordion.Header>
          <Accordion.Body>
            <p>
              All characters are now visible by default and we added special
              markings to show characters available only in the KR region.
            </p>
            <div className={`configuration-container`}>
              <div className="sorting">
                <span>Sorting</span>
                <Select
                  options={sortingOptions}
                  className="custom-dropdown order-2 order-md-2"
                  isSearchable={false}
                  onChange={sortChangeHandler}
                  defaultValue={defaultSortOption}
                  classNamePrefix="custom-dropdown-inner"
                />
              </div>
            </div>
            <br />
            <p>Special markings:</p>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faBolt}
                  width="18"
                  className="arrow-purple"
                />{' '}
                - part of the Terror Deck
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCube}
                  width="18"
                  className="arrow-red"
                />{' '}
                - has Alternium Reactor upgrade
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faSyringe}
                  width="18"
                  className="arrow-blue"
                />{' '}
                - is a Rearmed character
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="employees-container">
        {visibleEmployees.map((emp, index) => {
          return (
            <Card className="avatar-card" key={index}>
              <Employee
                slug={emp.slug}
                mode="icon"
                showLabel
                showSiege
                enablePopover
              />
            </Card>
          );
        })}
      </div>
      {visibleEmployees.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default CharactersPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Counter Side | Prydwen Institute"
    description="List of Counter Side characters available on KR and Global servers."
    game="cs"
  />
);

export const pageQuery = graphql`
  query {
    allEmployees: allContentfulEmployee(
      sort: { fields: fullName, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        fullName
        slug
        shortName
        seaReleaseDate
        krReleaseDate
        globalReleaseDate
        regions
        rarity
        rarityNumber
        rarityClassName
        title
        alterniumInfo {
          name
          type
          skill_name
          description
        }
        hasAlternium
        isRearmed
        type
        role
        movementType
        attackType
        speciality
        newRatings {
          pvp_sea
          pvp_global
          pve_early
          pve_late
        }
        isAwakened
        showNewTag
        hasBeenBuffedRecently
      }
    }
  }
`;
